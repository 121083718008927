import { createApp } from 'vue';
import i18n from '../vue/i18n';
import CaseList from '../vue/components/case-list.vue';

export function setupVue() {

    const app = createApp();

    // We need this to make sure "white-space: pre-line" still works
    app.config.compilerOptions.whitespace = 'preserve';

    app.component('CaseList', CaseList);

    app.use(i18n);
    app.mount('#app');
}
