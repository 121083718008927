import { isVisible } from '../utils/helpers';
import { enableScrollLock, disableScrollLock  } from '../utils/scroll';

let activeOverlay,
    activeOverlayInner,
    activeOverlayCloseBtn;

const employeeListSelector = '.employee-list',
    employeeDepartmentHiddenClass = 'employee-list__department-list--hidden',
    employeeOverlayOpenClass = 'employee-list__overlay--open';

function outsideClickListener(e) {

    if (!activeOverlayInner.contains(e.target) && isVisible(activeOverlayInner)) {
        overlayClose(e);
        activeOverlay.removeEventListener('click', outsideClickListener);
    }
}

function overlayOpen(e) {
    enableScrollLock();

    const target = e.target,
        employeeItem = e.currentTarget.closest('.employee-list__employee');

    activeOverlay = employeeItem.closest(employeeListSelector).querySelector('.employee-list__overlay');

    if (!target.classList.contains('employee-list__employee-link')) {
        typeof e.preventDefault == 'function' ? e.preventDefault() : null;

        if (activeOverlay) {
            activeOverlay.classList.add(employeeOverlayOpenClass);
            activeOverlayInner = activeOverlay.querySelector('.employee-list__overlay-inner');
            activeOverlayCloseBtn = activeOverlay.querySelector('.employee-list__overlay-close');

            if (activeOverlayCloseBtn) {
                activeOverlayCloseBtn.addEventListener('click', overlayClose);
            }

            activeOverlay.addEventListener('click', outsideClickListener);

            const overlayContent = activeOverlay.querySelector('.employee-list__overlay-content');
            if (overlayContent) {
                const employeeItemCloned = employeeItem.cloneNode(true);

                overlayContent.innerHTML = '';
                overlayContent.appendChild(employeeItemCloned);
            }
        }
    }
}

function overlayClose(e) {
    e.preventDefault();
    disableScrollLock();
    activeOverlay.classList.remove(employeeOverlayOpenClass);

    if (activeOverlayCloseBtn) {
        activeOverlayCloseBtn.removeEventListener('click', overlayClose);
    }
}

function updateFilter(e) {
    const selectedTarget = e.target,
        selectedValue = selectedTarget.value,
        selectedEmployeeDepartments = selectedTarget.closest(employeeListSelector).querySelectorAll('.employee-list__department-list');

    if (selectedEmployeeDepartments.length) {
        selectedEmployeeDepartments.forEach(employeeDepartment => {
            const filterValue = employeeDepartment.getAttribute('data-filter');
            if (selectedValue && filterValue != selectedValue) {
                employeeDepartment.classList.add(employeeDepartmentHiddenClass);
            } else {
                employeeDepartment.classList.remove(employeeDepartmentHiddenClass);
            }
        });
    }
}

export function setupEmployeeList() {
    const employeeLists = document.querySelectorAll(employeeListSelector);

    if (employeeLists.length) {

        employeeLists.forEach(employeeList => {
            const employeeFilter = employeeList.querySelector('[data-action="employee-filter"]'),
                employeeReadMoreLinks = employeeList.querySelectorAll('.employee-list__employee-read-more');

            if (employeeFilter) {
                employeeFilter.addEventListener('change', updateFilter);
            }

            if (employeeReadMoreLinks.length) {
                employeeReadMoreLinks.forEach(employeeReadMoreLink => {
                    employeeReadMoreLink.addEventListener('click', overlayOpen);
                });
            }
        });
    }
}
