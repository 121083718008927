import Swiper, { Navigation, Pagination, FreeMode } from 'swiper';

Swiper.use([Pagination, Navigation, FreeMode]);

export const swipers = [];

export function setupSwipers(selector = '[data-action~="swiper"]') {
    const swiperComponents = document.querySelectorAll(selector);

    // Clear existing swipers if swiperComponents contains existing swipers
    const existingSwipers = swipers.filter(x => Array.from(swiperComponents).includes(x.el));
    existingSwipers.forEach(existingSwiper => {
        const indexInSwiperArray = swipers.findIndex(x => x === existingSwiper);
        existingSwiper.destroy(true, true);
        swipers.splice(indexInSwiperArray, 1);
    });

    // Add swipers
    swiperComponents.forEach(swiperComponent => {

        // Define swiper container
        let swiperContainer = swiperComponent;
        if (swiperComponent.hasAttribute('data-swiper-container')) {
            swiperContainer = document.getElementById(swiperComponent.getAttribute('data-swiper-container')) ?? swiperComponent;
        }

        // Swiper options
        const touchReleaseOnEdges = swiperComponent.getAttribute('data-swiper-touch-release-on-edge')?.toLowerCase() === 'true';
        const freeMode = swiperComponent.getAttribute('data-swiper-freemode')?.toLowerCase() === 'true';
        const centeredSlides = swiperComponent.getAttribute('data-swiper-centered-slides')?.toLowerCase() === 'true';
        const centeredSlidesBounds = swiperComponent.getAttribute('data-swiper-centered-slides-bounds')?.toLowerCase() === 'true';
        const initialSlide = swiperComponent.getAttribute('data-swiper-centered-initial-slide') ?? 0;

        // Define swiper
        const swiper = new Swiper(swiperContainer, {
            pagination: {
                el: swiperComponent.querySelector('.swiper__pagination'),
                bulletClass: 'swiper__pagination-bullet',
                bulletActiveClass: 'swiper__pagination-bullet--active',
                clickable: true
            },
            navigation: {
                nextEl: swiperComponent.querySelector('.swiper__button-next'),
                prevEl: swiperComponent.querySelector('.swiper__button-prev')
            },
            slidesPerView: 'auto',
            speed: 500,
            slideToClickedSlide: false,
            touchReleaseOnEdges,
            freeMode,
            centeredSlides,
            centeredSlidesBounds,
            initialSlide
        });

        // Responsive settings
        swiper.on('resize', () => {

            // The resize function is triggered afters swipers default init event, which is why this class is being added here to prevent prev/next buttons showing up on page load
            if (!swiperComponent.classList.contains('swiper--initialized')) {
                swiperComponent.classList.add('swiper--initialized');
            }

            if (swiper.isBeginning && swiper.isEnd || (swiper.params.centeredSlides && swiper.wrapperEl && swiper.wrapperEl.scrollWidth <= swiperContainer.scrollWidth)) {
                swiperComponent.classList.add('swiper--not-interactive');
                swiper.params.enabled = false;
                swiper.disable();
            } else {
                swiperComponent.classList.remove('swiper--not-interactive');
                swiper.params.enabled = true;
                swiper.enable();
            }
        });

        swipers.push(swiper);
    });
}
