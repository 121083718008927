import { onReady } from './utils/on-ready';
import { onWindowResize } from './utils/window-resize';
import { isTouch, initVhUnitOverwrite } from './utils/helpers';
import { initAnchorScroll } from './utils/scroll';
import { setupVue } from './components/vue';
import { setupNavigation } from './layout/navigation';
import { calculateIframeSize } from './components/iframe-resize';
import { setupSearch } from './components/search';
import { setupVimeoVideos } from './components/vimeo-video';
import { setupSwipers } from './components/swiper';
import { setupEmployeeList } from './modules/employee-list';
// import { setupThemeSwitcher } from './components/theme-switcher';

function init() {

    setTimeout(() => {
        document.body.classList.remove('standby');
    }, 0);

    if (isTouch) {
        document.body.classList.add('touch-device');
    }

    setupVue();

    onReady(() => {
        initVhUnitOverwrite(calculateIframeSize);
        initAnchorScroll();
        setupNavigation();
        setupSearch();
        setupVimeoVideos();
        setupSwipers();
        setupEmployeeList();

        // Only use this during test-mode c'')
        // setupThemeSwitcher();
    });

    onWindowResize(() => {
        calculateIframeSize();
    });
}

init();
