import { stickyNavigation } from '../components/sticky-navigation';
import { enableScrollLock, disableScrollLock } from '../utils/scroll';

export let navigation;

const openClass = 'navigation--open',
    stickyClass = 'navigation--sticky',
    goingUpClass = 'navigation--going-up';

export function toggleMenuOpen() {
    if (navigation.classList.contains(openClass)) {
        disableScrollLock();
        navigation.classList.remove(openClass);

        if (window.pageYOffset > 50) {
            setTimeout(() => navigation.classList.add(goingUpClass), 40);
        }

    } else {
        enableScrollLock();
        navigation.classList.add(openClass);
    }
}

export function setupNavigation(selector = '.navigation', sticky = true) {
    navigation = document.body.querySelector(selector);

    if (navigation) {
        const navigationBtn = navigation.querySelector('.navigation__btn');

        if (navigationBtn) {
            navigationBtn.addEventListener('click', toggleMenuOpen);
        }

        if (sticky) {
            stickyNavigation(navigation, stickyClass, 30, goingUpClass);
        }
    }
}
