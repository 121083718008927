<template>
    <div class="case-list__app">
        <div v-if="projects.length || facades.length" class="filter">
            <span class="filter__toggle" @click="showFilter = !showFilter">
                {{ showFilter ? $t('Dictionary.Filter.Hide') : $t('Dictionary.Filter.Show') }}
            </span>
            <div class="filter__inner" :class="{ 'filter__inner--active': showFilter }">
                <div v-if="projects.length" class="filter__col">
                    <span class="filter__headline">{{ $t('Dictionary.Cases.FilterProjects') }}</span>
                    <div class="filter__checkbox-list">
                        <div v-for="projectItem in projects" :key="projectItem.id" class="filter__checkbox-wrap">
                            <input :id="'project-' + projectItem.key" v-model="selectedProjects" class="filter__checkbox" type="checkbox" :value="projectItem.key" />
                            <label :for="'project-' + projectItem.key" class="filter__checkbox-label">{{ projectItem.name }}</label>
                        </div>
                    </div>
                </div>
                <div v-if="facades.length" class="filter__col">
                    <span class="filter__headline">{{ $t('Dictionary.Cases.FilterFacades') }}</span>
                    <div class="filter__checkbox-list">
                        <div v-for="facadeItem in facades" :key="facadeItem.id" class="filter__checkbox-wrap">
                            <input :id="'facade-' + facadeItem.key" v-model="selectedFacades" class="filter__checkbox" type="checkbox" :value="facadeItem.key" />
                            <label :for="'facade-' + facadeItem.key" class="filter__checkbox-label">{{ facadeItem.name }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card__list-wrap">
            <div v-if="filteredCases.length" class="card__list">
                <div v-for="caseItem in filteredCases" :key="caseItem.id" class="card__item">
                    <a :href="caseItem.url" :title="caseItem.headline" class="card__item-inner">
                        <picture v-if="Object.keys(caseItem.imageUrls).length > 0" class="card__item-image-wrap">
                            <source :srcset="caseItem.imageUrls.xxl" media="(min-width: 1920px)" />
                            <source :srcset="caseItem.imageUrls.md" media="(min-width: 992px)" />
                            <source :srcset="caseItem.imageUrls.xs" media="(min-width: 480px)" />
                            <img class="card__item-image" loading="lazy" :src="caseItem.imageUrls.default" :alt="caseItem.imageAltText" />
                        </picture>
                        <div class="card__item-text theme--white">
                            <div class="card__item-text-inner">
                                <h3 class="card__item-headline">{{ caseItem.headline }}</h3>
                                <span v-if="caseItem.teaser" class="card__item-teaser">{{ caseItem.teaser }}</span>
                            </div>
                            <div class="card__item-link-icon-wrap">
                                <span class="btn btn--icon-only">
                                    <span class="btn__icon">
                                        <svg-inline name="arrow" />
                                    </span>
                                    <span class="btn__icon">
                                        <svg-inline name="arrow" />
                                    </span>
                                </span>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <p v-if="!isLoading && !filteredCases.length" class="card__list-info">
                {{ $t('Dictionary.Cases.NoResults') }}
            </p>
        </div>
        <div class="spinner-wrap" :aria-hidden="isLoading ? 'false' : 'true'">
            <div class="spinner"></div>
        </div>
    </div>
</template>

<script>
import apiService from '../../api';
import SvgInline from '../components/svg-inline.vue';

export default {
    name: 'CaseList',
    components: {
        SvgInline
    },
    props: {
        pageId: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            isLoading: false,
            showFilter: false,
            cases: [],
            projects: [],
            facades: [],
            selectedProjects: [],
            selectedFacades: []
        };
    },
    computed: {
        filteredCases() {
            const vm = this;
            const filteredCases = vm.cases
                // Projects
                .filter(caseItem => vm.selectedProjects.every(x => caseItem.projects.map(y => y.key).includes(x)))
                // Facades
                .filter(caseItem => vm.selectedFacades.every(x => caseItem.facades.map(y => y.key).includes(x)));

            return filteredCases;
        }
    },
    created() {
        this.getCases();
    },
    methods: {
        getCases() {
            const vm = this;
            vm.isLoading = true;

            apiService.cases.getCases(vm.pageId)
                .then(response => {
                    vm.cases = response.data.cases;
                    vm.projects = response.data.projects;
                    vm.facades = response.data.facades;
                })
                .catch(errors => {
                    console.log(`Error (getAllCases): ${errors}`);
                })
                .then(function () {
                    vm.isLoading = false;
                });
        },
    }
};
</script>
