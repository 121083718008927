import axios from 'axios';

const culture = document.documentElement.lang;

const apiService = {
    cases: {
        getCases(pageId) {
            return axios.get('/Umbraco/Api/CaseApi/GetAllCases', { params: { culture, pageId } }).then(response => response);
        }
    }
};

export default apiService;
