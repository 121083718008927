// eslint-disable-next-line no-undef
export const allowStatCookies = window.CookieInformation && CookieInformation.getConsentGivenFor('cookie_cat_statistic');
export const isTouch = 'ontouchstart' in window;
export const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

export function setVhProp(e, callback) {
    const delay = e != undefined && e.type == 'orientationchange' ? 100 : 0;
    setTimeout(() => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        if (callback) {
            callback();
        }
    }, delay);
}

export function initVhUnitOverwrite(callback = null) {
    setVhProp(undefined, callback);
    window.addEventListener('orientationchange', e => {
        setVhProp(e, callback);
    });
}

// Prevent function from being executed as long as it is invoked, while given delay hasn't passed.
export function debounce(callback, delay) {
    let timer = null;

    return function() {
        const context = this,
            args = arguments;

        clearTimeout(timer);

        timer = setTimeout(function() {
            callback.apply(context, args);
        }, delay);
    };
}

// Check if an element is in the viewport
export function isVisible(elem) {
    return !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
}

// Find out whether or not the given argument is an element that would react somewhat normally to DOM-manipulations.
export function isElement(element) {
    return element instanceof Element || element instanceof Document || element instanceof Window;
}

// Get the current scroll values of the given element (or window)
export function getElementScroll(element = window) {
    if (isElement(element)) {
        if (element instanceof Window) {
            return {
                left: element.pageXOffset || document.documentElement.scrollLeft,
                top: element.pageYOffset || document.documentElement.scrollTop
            };
        } else {
            return {
                left: element.scrollX || element.scrollLeft,
                top: element.scrollY || element.scrollTop
            };
        }
    } else {
        console.warn('Can\'t get scroll-position or given argument type.');
        return false;
    }
}

// Native javascript for sorting instead of using Lodash
export function orderBy(keys, orders) {
    let cb = () => 0;
    keys.reverse();
    orders.reverse();
    for (const [i, key] of keys.entries()) {
        const order = orders[i];
        if (order == 'asc')
            cb = sortBy(key, cb);
        else if (order == 'desc')
            cb = sortByDesc(key, cb);
        else
            throw new Error(`Unsupported order "${order}"`);
    }
    return cb;
}

function sortBy(key, cb) {
    // eslint-disable-next-line no-param-reassign
    if (!cb) cb = () => 0;
    return (a, b) => (a[key] > b[key]) ? 1 :
        ((b[key] > a[key]) ? -1 : cb(a, b));
}

function sortByDesc(key, cb) {
    // eslint-disable-next-line no-param-reassign
    if (!cb) cb = () => 0;
    return (b, a) => (a[key] > b[key]) ? 1 :
        ((b[key] > a[key]) ? -1 : cb(b, a));
}
