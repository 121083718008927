<template>
    <template v-if="name == 'arrow'">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.27 13.4">
            <path d="M15.9,6.29h0L10.02,.41c-.21-.25-.58-.28-.83-.07s-.28,.58-.07,.83c.02,.02,.04,.05,.07,.07l4.88,4.87H.79c-.32,0-.59,.26-.59,.59,0,.32,.26,.59,.59,.59H14.06l-4.87,4.87c-.25,.21-.28,.58-.07,.83s.58,.28,.83,.07c.02-.02,.05-.04,.07-.07l5.88-5.88c.23-.23,.23-.6,0-.83h0Z" />
            <path d="M0,6.7c0-.43,.35-.79,.79-.79H13.58L9.05,1.38l-.08-.08c-.14-.16-.21-.37-.19-.57,.02-.21,.11-.4,.27-.54,.17-.14,.38-.2,.59-.19s.4,.11,.54,.27l5.86,5.88c.3,.31,.3,.8,0,1.11l-5.88,5.88s-.04,.05-.07,.07c-.33,.28-.83,.24-1.11-.09s-.24-.83,.09-1.11l4.51-4.52H.79c-.43,0-.78-.35-.79-.79H0Zm14.55-.39H.79c-.21,0-.39,.17-.39,.39H.4c0,.21,.17,.39,.39,.39H14.55l-5.21,5.21c-.17,.15-.19,.39-.05,.56,.14,.16,.38,.18,.55,.04l.04-.04,5.88-5.89c.15-.15,.15-.4,0-.55L9.88,.55c-.08-.09-.17-.14-.27-.15-.1,0-.2,.02-.28,.09-.16,.14-.18,.38-.04,.55,.01,.02,.03,.03,.04,.04l5.22,5.23Z" />
        </svg>
    </template>
    <template v-else-if="name == 'search'">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.3 16.31">
            <path d="M16,14.64l-3.12-3.13c2.4-3.15,1.79-7.65-1.37-10.05S3.86-.32,1.46,2.83C-.93,5.98-.32,10.48,2.83,12.88c2.56,1.95,6.12,1.95,8.68,0l3.12,3.12c.36,.39,.97,.41,1.36,.05,.39-.36,.41-.97,.05-1.36-.02-.02-.03-.03-.05-.05h0ZM3.49,10.89c-2.04-2.04-2.04-5.35,0-7.39,2.04-2.04,5.35-2.04,7.39,0,2.04,2.04,2.04,5.35,0,7.39-.98,.98-2.31,1.53-3.69,1.53-1.39,0-2.72-.55-3.7-1.53Z" />
        </svg>
    </template>
    <template v-else-if="name == 'play'">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 207.11 239.17">
            <path d="m0,0l207.11,119.57L0,239.17V0Z" />
        </svg>
    </template>
</template>

<script>
export default {
    name: 'SvgInline',
    props: {
        name: {
            type: String,
            default: ''
        }
    }
};
</script>
